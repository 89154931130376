import React, { useEffect, useRef, useContext } from "react";
import { css } from "@emotion/react";
import { Div } from "@components/Markup";
import Player from "@vimeo/player";
import { ITEM_WIDTH } from "../layouts/page-nav-data";
import { ResizeContext } from "@providers/ResizeProvider";
import Spinner from "@components/Spinner";

const calculateVideoSize = () => {
  let size = { w: 0, h: 0 };
  const menuItemsWidth = ITEM_WIDTH * 5;

  if (typeof window === "object") {
    if (window.innerWidth > 992) {
      //check proportions
      let useWidth =
        (window.innerWidth - menuItemsWidth) / window.innerHeight > 1.78;
      if (useWidth) {
        size.w = window.innerWidth - menuItemsWidth;
        size.h = 1.78 * size.w;
      } else {
        size.h = window.innerHeight - 128;
        size.w = 1.78 * size.h;
      }
    } else {
      // def use height ;)
      size.h = window.innerHeight - 64;
      size.w = 1.78 * size.h;
    }
  }

  return size;
};

const Hero = ({
  featuredImage,
  featuredGallery,
  featuredVideoId,
  children,
}) => {
  const videoContainerRef = useRef(null);
  const playerRef = useRef(null);
  calculateVideoSize();
  const bgImage = featuredImage
    ? featuredImage.fluid.src
    : featuredGallery.length > 0
    ? featuredGallery[0].fluid.src
    : null;

  const { addResizeCallback } = useContext(ResizeContext);

  const initVideo = () => {
    if (playerRef.current) {
      playerRef.current.destroy();
    }

    const { w, h } = calculateVideoSize();
    if (featuredVideoId) {
      playerRef.current = new Player(videoContainerRef.current, {
        id: featuredVideoId,
        title: false,
        controls: false,
        byline: false,
        background: true,
        autoplay: true,
        muted: true,
        loop: true,
        width: w,
        height: h,
      });
    }
  };

  useEffect(() => {
    if (featuredVideoId) {
      initVideo();
    }
  }, []);

  useEffect(() => {
    return addResizeCallback(initVideo);
  }, []);

  return (
    <Div
      mb="lg"
      css={css`
        display: block;
        position: relative;
        width: 100%;
        height: calc(100vh - 64px);
        overflow: hidden;
        background: black;

        @media (max-width: 767px) {
          display: none;
        }

        @media (min-width: 992px) {
          height: calc(100vh - 128px);
        }
      `}
    >
      <Spinner />
      {bgImage && !featuredVideoId && (
        <div
          css={css`
            background-image: url("${bgImage}");
            background-size: cover;
            background-position: center center;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
          `}
        />
      )}
      {featuredVideoId && (
        <div
          ref={videoContainerRef}
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            color: white;
            height: 100%;
            width: 100%;
            iframe {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0);
            }
          `}
        />
      )}
      <Div
        flex
        jc="flex-end"
        ai="flex-start"
        pl="sm"
        pb="md"
        css={css`
          flex-direction: column;
          background: rgb(51, 51, 51);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 51%,
            rgba(255, 255, 255, 0) 100%
          );
          position: absolute;
          top: 0;
          left: 0;
          color: white;
          height: 100%;
          width: 100%;
        `}
      >
        {children}
      </Div>
    </Div>
  );
};

export default Hero;
