import React, { useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { css } from "@emotion/react";
import { Div, Container } from "@components/Markup";
import { Title } from "@components/Typography";

// const Client = ({ name, logo }) => {
//   return (
//     <Div
//       flex
//       ai="center"
//       jc="center"
//       css={css`
//         min-width: 16.66%;
//         width: 16.66%;
//         padding: 0px 16px;

//         svg {
//           max-width: 100%;
//           width: 100%;
//           max-height: 100px;
//           max-width: 120px;

//           path,
//           polyline,
//           polygon {
//             fill: #aaaaaa !important;
//           }
//         }
//       `}
//     >
//       <Div dangerouslySetInnerHTML={{ __html: logo }} />
//     </Div>
//   );
// };

const ClientList = () => {
  // const [clients, setClients] = useState(null);

  return (
    <Container>
      <Div pt="lg">
        <Title tag="h2" size="sm" mb="sm">
          Unsere Kunden
        </Title>
        <StaticQuery
          query={graphql`
            query ClientsQuery {
              allDatoCmsClient {
                edges {
                  node {
                    id
                    websiteUrl
                    logo {
                      fluid {
                        src
                        srcSet
                        aspectRatio
                      }
                    }
                  }
                }
              }
            }
          `}
          render={data => {
            const c = data.allDatoCmsClient.edges.map(edge => edge.node);
            // if (!clients) {
            //   setClients(c);
            // }
            return (
              <Div
                flex
                css={css`
                  flex-wrap: wrap;
                `}
              >
                {c &&
                  c.map((client, index) => {
                    return (
                      <a
                        href={client.websiteUrl}
                        key={`client--${index}`}
                        target="_blank"
                        css={css`
                          min-width: 50%;
                          max-width: 50%;
                          border: 2px solid red;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          padding: 16px 0px;
                          border: 2px solid transparent;
                          transition: 0.15s all;

                          &:hover {
                            background-color: #f0f0f0;
                          }

                          img {
                            max-width: 200px;
                            max-height: 100px;
                            object-fit: contain;
                            mix-blend-mode: multiply;
                            @media (max-width: 767px) {
                              max-width: 100%;
                            }
                          }

                          @media (min-width: 768px) {
                            min-width: 33.33%;
                            max-width: 33.33%;
                            padding: 32px 16px;
                          }
                        `}
                      >
                        <img src={client.logo.fluid.src} />
                      </a>
                    );
                  })}
              </Div>
            );
          }}
        />
      </Div>
    </Container>
  );
};

export default ClientList;
