import React from "react";
import { css } from "@emotion/react";
import { Div } from "@components/Markup";
import { Title, Text } from "@components/Typography";
import Image from "gatsby-image";
import IosMailOutline from "react-ionicons/lib/IosMailOutline";

const TeamMember = ({
  name,
  companyPosition,
  featuredImage,
  bio,
  emailAddress,
}) => {
  return (
    <Div
      mb="md"
      css={css`
        width: 100%;
        @media (min-width: 768px) {
          max-width: 33.33%;
          padding: 0px 8px;
        }
      `}
    >
      <a
        href={`mailto:${emailAddress}`}
        css={css`
          cursor: pointer;
          position: relative;

          &:hover {
            .overlay {
              opacity: 1;
            }
          }
        `}
      >
        <Div
          mb="xs"
          css={css`
            width: 100%;
            position: relative;
          `}
        >
          {featuredImage && <Image fluid={featuredImage.fluid} />}
          <Div
            className="overlay"
            flex
            ai="center"
            jc="center"
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              background: rgba(0, 0, 0, 0.45);
              height: 100%;
              width: 100%;
              opacity: 0;
              transition: 0.15s all;
              flex-direction: column;
              color: white;
              padding: 16px 8px;

              svg {
                margin-bottom: 16px;
              }
            `}
          >
            <IosMailOutline fontSize="48px" color="white" />
            <Text
              mb="xs"
              bold
              css={css`
                line-height: 1.2em !important;
                font-size: 14px !important;
                text-align: left;
                width: 100%;
              `}
            >
              {" "}
              {emailAddress}
            </Text>
            <Text
              css={css`
                line-height: 1.2em !important;
                font-size: 14px !important;
                white-space: pre-wrap;
                text-align: left;
                width: 100%;
              `}
            >
              {bio}
            </Text>
          </Div>
        </Div>
        <Div>
          <Title size="xs" mb="xs" bold={false}>
            {name}
          </Title>
          <Text textStyle="label">{companyPosition}</Text>
        </Div>
      </a>
    </Div>
  );
};

export default TeamMember;
