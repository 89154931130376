import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { css } from "@emotion/react";
import { Section, Div, Container } from "@components/Markup";
import TeamMember from "./TeamMember";

const TeamMembersGrid = () => {
  return (
    <Section pt="md" pb="md">
      <StaticQuery
        query={graphql`
          query TeamQuery {
            allDatoCmsTeamMember(sort: { fields: [position], order: ASC }) {
              edges {
                node {
                  id
                  name
                  bio
                  companyPosition
                  phoneNumber
                  emailAddress
                  featuredImage {
                    fluid {
                      src
                      srcSet
                      aspectRatio
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const members = data.allDatoCmsTeamMember.edges.map(
            edge => edge.node
          );
          return (
            <Container>
              <Div
                flex
                css={css`
                  flex-wrap: wrap;
                  width: 100%;
                `}
              >
                {members &&
                  members.map((member, index) => (
                    <TeamMember key={`member--${index}`} {...member} />
                  ))}
              </Div>
            </Container>
          );
        }}
      />
    </Section>
  );
};

export default TeamMembersGrid;
