import React from "react";
import { graphql } from "gatsby";
import { Section, Container } from "@components/Markup";
import { renderModularContent } from "@components/renderPage";
import SEO from "@components/seo";
import TeamMembersGrid from "@components/TeamMembersGrid";
import ClientList from "@components/Clients";
import PageHero from "@components/PageHero";
import Leistungen from "@components/Leistungen";

const Page = ({ data }) => {
  const {
    content,
    pageTitle,
    pageSlogan,
    slug,
    seoTitle,
    seoImage,
    seoDescription,
    featuredGallery,
    featuredVideoId,
  } = data.page;

  const seoDisplayImage = seoImage ? seoImage.fluid.src : null;
  const seoDisplayTitle =
    seoTitle || `${pageTitle} | Hawlik Gerginski Architekten`;
  const seoDisplayDescription = seoDescription || pageSlogan;

  return (
    <React.Fragment>
      <SEO
        title={seoDisplayTitle}
        image={seoDisplayImage}
        description={seoDisplayDescription}
      />
      <PageHero
        pageTitle={pageTitle}
        pageSlogan={pageSlogan}
        featuredGallery={featuredGallery}
        featuredVideoId={featuredVideoId}
      />
      <Section>
        <Container>{renderModularContent({ content })}</Container>
        {slug === "uber-uns" && <TeamMembersGrid />}
        {slug === "leistungen" && <Leistungen />}
        {slug === "leistungen" && <ClientList />}
      </Section>
    </React.Fragment>
  );
};

export default Page;

export const query = graphql`
  query PageQuery($slug: String!) {
    page: datoCmsPage(slug: { eq: $slug }) {
      pageTitle
      pageSlogan
      featuredGallery {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
      featuredVideoId
      slug
      seoTitle
      seoDescription
      seoImage {
        fluid {
          src
          srcSet
          aspectRatio
        }
      }
      content {
        ... on DatoCmsGallery {
          items {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
        }
        ... on DatoCmsText {
          text
        }
        ... on DatoCmsDocumentDownload {
          documentName
          documentFile {
            url
          }
        }
        ... on DatoCmsImageText {
          text
          image {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
        }
        ... on DatoCmsHighlight {
          text
        }
        ... on DatoCmsQuote {
          text
          source
        }
        ... on DatoCmsImage {
          images {
            fluid {
              src
              srcSet
              aspectRatio
            }
          }
        }
      }
    }
  }
`;
