import React from "react";
import { css } from "@emotion/react";

const Spinner = () => {
  return (
    <div
      css={css`
        display: inline-block;

        @keyframes lds-dual-ring {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
        width: 60px;
        height: 60px;

        :after {
          content: " ";
          display: block;
          width: 34px;
          height: 34px;
          margin: 13px;
          border-radius: 50%;
          border: 2px solid #fff;
          border-color: #fff transparent #fff transparent;
          animation: lds-dual-ring 1.2s linear infinite;
        }
      `}
    />
  );
};

export default Spinner;
