import React from "react";
import { Title } from "@components/Typography";
import Hero from "@components/Hero";
import { css } from "@emotion/react";
import { Div, Container } from "@components/Markup";
import ScrollIndicator from "./ScrollIndicator";
import HeroGallery from "./HeroGallery";

const PageHero = ({
  pageTitle,
  pageSlogan,
  featuredVideoId,
  featuredGallery,
}) => {
  return (
    <Div>
      <Div
        css={css`
          @media (min-width: 768px) {
            display: none;
          }
        `}
      >
        {featuredGallery.length > 0 && <HeroGallery items={featuredGallery} />}
        <Container>
          <Title size="xl" tag="h1" uppercase pt="md">
            {pageTitle}
          </Title>
          <Title size="md" pb="lg">
            {pageSlogan}
          </Title>
        </Container>
      </Div>
      <Hero featuredVideoId={featuredVideoId} featuredGallery={featuredGallery}>
        <Div
          flex
          ai="flex-end"
          jc="space-between"
          css={css`
            width: calc(100% - 20px);
            @media (min-width: 768px) {
              width: calc(100% - 50px);
            }
          `}
        >
          <Div
            css={css`
              max-width: 90%;
            `}
          >
            <Title size="xl" tag="h1" uppercase>
              {pageTitle}
            </Title>
            <Title size="md">{pageSlogan}</Title>
          </Div>
          <ScrollIndicator />
        </Div>
      </Hero>
    </Div>
  );
};

export default PageHero;
