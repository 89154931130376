import React from "react";
import { css } from "@emotion/react";
import { forEach, cloneDeep } from "lodash";
import { nanoid } from "nanoid";

import TextBlock from "@components/TextBlock";
import Gallery from "@components/Gallery";
import Images from "@components/Images";
import Highlight from "@components/Highlight";
import Quote from "@components/Quote";
import ImageText from "@components/ImageText";
import DocumentDownload from "@components/DocumentDownload";

const ELEMENTS = {
  text: { component: TextBlock },
  gallery: { component: Gallery },
  images: { component: Images },
  highlight: { component: Highlight },
  quote: { component: Quote },
  imageText: { component: ImageText },
  documentDownload: { component: DocumentDownload },
};

const MAP_DATO_TO_ZAS = {
  DatoCmsText: "text",
  DatoCmsGallery: "gallery",
  DatoCmsHighlight: "highlight",
  DatoCmsImage: "images",
  DatoCmsQuote: "quote",
  DatoCmsImageText: "imageText",
  DatoCmsDocumentDownload: "documentDownload",
};

export const renderModularContent = ({ content }) => {
  let modules = [];

  forEach(content, module => {
    const zasKey = MAP_DATO_TO_ZAS[module.__typename];

    if (zasKey) {
      let moduleData = cloneDeep(module);
      delete moduleData.__typename;
      modules.push({ type: zasKey, data: moduleData });
    }
  });

  return (
    <div
      css={css`
        white-space: pre-wrap;
      `}
    >
      {modules &&
        modules.map((element, index) => {
          const Element = ELEMENTS[element.type];
          const ElementComponent = Element ? Element.component : null;

          if (ElementComponent)
            return <ElementComponent key={nanoid()} {...element.data} />;

          return null;
        })}
    </div>
  );
};

const renderPage = ({ data, modular }) => {
  let modules = [];
  forEach(modular, (module, index) => {
    const zasKey = MAP_DATO_TO_ZAS[module.__typename];

    if (zasKey) {
      let moduleData = cloneDeep(module);
      delete moduleData.__typename;
      modules.push({ type: zasKey, data: moduleData });
    }
  });

  return (
    <div>
      {modules &&
        modules.map((element, index) => {
          const Element = ELEMENTS[element.type];
          const ElementComponent = Element ? Element.component : null;

          if (ElementComponent)
            return (
              <ElementComponent key={`modular--${index}`} {...element.data} />
            );

          return null;
        })}
    </div>
  );
};

export default renderPage;
