import React from "react";
import { css } from "@emotion/react";
import { Div } from "@components/Markup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GALLERY_HEIGHT = 100;

const SliderItem = ({ fluid }) => {
  return (
    <Div
      flex
      css={css`
        height: 66vw;
        width: 100vw;
        margin: 0 auto;
        position: relative;

        @media (min-width: 992px) {
          //height: ${(1 / fluid.aspectRatio) * 100}vw;
          height: ${GALLERY_HEIGHT}vh;
          width: calc(100vw - 240px);
        }
      `}
      className="zas-section__item"
    >
      <div
        css={css`
          position: absolute !important;
          top: 50%;
          left: 50%;
          height: 100%;
          width: 100%;
          transform: translate3d(-50%, -50%, 0);
        `}
      >
        <img src={fluid.src} srcSet={fluid.srcSet} alt={fluid.src} />
      </div>
    </Div>
  );
};

const defaultSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  touchThreshold: 13,
  centerPadding: 0,
  autoplay: true,
};

const HeroGallery = ({ children, items }) => {
  return (
    <Div
      mb="lg"
      css={css`
        display: block;
        position: relative;
        width: 100%;
        overflow: hidden;
        background: black;

        @media (max-width: 767px) {
          //display: none;
        }

        @media (min-width: 992px) {
          height: calc(100vh - 128px);
        }
      `}
    >
      <Slider
        {...defaultSettings}
        css={css`
          .slick-dots {
          }

          .slick-track {
            display: flex;
          }

          .slick-slide {
            opacity: 1;
            transition: opacity 0.1s;
            outline: none !important;
          }

          .slick-next {
            right: 10px;
          }

          .slick-prev {
            left: 10px;
            z-index: 20;
          }
        `}
      >
        {items.map((item, index) => (
          <SliderItem key={`item--${index}`} {...item} />
        ))}
      </Slider>
      <Div
        flex
        jc="flex-end"
        ai="flex-start"
        pl="sm"
        pb="md"
        css={css`
          flex-direction: column;
          background: rgb(51, 51, 51);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 1) 0%,
            rgba(0, 0, 0, 0) 51%,
            rgba(255, 255, 255, 0) 100%
          );
          position: absolute;
          top: 0;
          left: 0;
          color: white;
          height: 100%;
          width: 100%;
        `}
      >
        {children}
      </Div>
    </Div>
  );
};

export default HeroGallery;
