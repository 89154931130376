import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Div, Container, Section } from "@components/Markup";
import { css } from "@emotion/react";
import { Title, Text } from "@components/Typography";

const Leistung = ({ title, subfields, description, downloads }) => {
  return (
    <Div
      css={css`
        flex-basis: 0;
        flex-grow: 1;
        margin-bottom: 32px;
        box-sizing: border-box;

        ul {
          margin: 0px;
          padding: 0px;
          list-style: none;
          margin-bottom: 32px;

          li {
            font-family: "lucida-sans";
            position: relative;
            padding-left: 24px;

            &:before {
              content: "";
              position: absolute;
              top: 12px;
              left: 0;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              background-color: #333;
            }
          }
        }

        @media (min-width: 768px) {
          padding: 0px 16px;
          margin-bottom: 0px;
          max-width: 33.33%;
        }
      `}
    >
      <Div>
        <Title tag="h2" size="sm" mb="sm">
          {title}
        </Title>
        <ul
          css={css`
            @media (min-width: 768px) {
              height: 120px;
            }
          `}
        >
          {subfields.map((subfield, index) => {
            return (
              <li
                key={`bp--${index}`}
                css={css`
                  margin-bottom: 8px;
                `}
              >
                {subfield.text}
              </li>
            );
          })}
        </ul>
        <Text
          mb="sm"
          css={css`
            white-space: pre-wrap;
          `}
        >
          {description}
        </Text>
      </Div>
    </Div>
  );
};

const Leistungen = () => {
  return (
    <Section pb="md">
      <StaticQuery
        query={graphql`
          query LeistungQuery {
            allDatoCmsLeistung {
              edges {
                node {
                  id
                  title
                  description
                  subfields {
                    ... on DatoCmsText {
                      text
                    }
                  }
                  downloads {
                    ... on DatoCmsDocumentDownload {
                      documentName
                      documentFile {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const leistungen2 = data.allDatoCmsLeistung.edges.map(
            edge => edge.node
          );
          return (
            <Container>
              <Div
                mb="md"
                flex
                css={css`
                  flex-direction: column;
                  @media (min-width: 768px) {
                    flex-direction: row;
                    margin: 0px -16px;
                  }
                `}
              >
                {leistungen2.map((leistung, index) => (
                  <Leistung key={`leistung--${index}`} {...leistung} />
                ))}
              </Div>
            </Container>
          );
        }}
      />
    </Section>
  );
};

export default Leistungen;
